import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import FormCustomer from '@/components/FormCustomer/FormCustomer/index.vue'
import FormCustomerModel from '@/Models/formCustomer'
import http from '@/services/http'
import menuModule from '@/store/menu'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    FormCustomer,
    Animation,
  },
})
export default class CustomerView extends Vue {
  alreadyForm = false
  data: FormCustomerModel = new FormCustomerModel()
  isAction = 'add'
  loading = false
  delete_modal_active = false
  baseUrl = ''
  isForm: FormCustomerModel = new FormCustomerModel()

  mounted() {
    this.getFormCustomer()
    this.baseUrl = process.env.VUE_APP_API_URL
  }

  async getFormCustomer() {
    try {
      const res = await http.get(`/api/client/client_residency/`)
      if (!res.data) throw new Error(res.data)
      if (res.data.length > 0) {
        this.alreadyForm = true
        this.data = res.data[0]
        setTimeout(() => {
          this.loading = true
        }, 1000)
      } else {
        this.alreadyForm = false
      }
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }

  editObj(props: any) {
    this.isForm = props
    console.log(this.isForm, 'formEdit')
    this.alreadyForm = false
    this.isAction = 'edit'
  }
  cancelEdit() {
    this.alreadyForm = true
    this.getFormCustomer()
  }
  addDependent(props: any) {
    const id = props.id.toString()
    menuModule.setIdParam(id)
    router.push({ name: 'customerDependent' })
  }
  /*  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objLegalDocument = props
  }
  closeModal() {
    this.objLegalDocument = new LegalDocument()
    this.delete_modal_active = false
  }
  openForm() {
    this.showList = false
  } */
  /*  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    this.objLegalDocument = new LegalDocument()
    this.showList = true
    this.action = 'add'
    await this.getLegalDocument()
  }
  isData(data: any) {
    this.data = data
  } */
}
